.cards-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.card-container {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.9);
    color: #000000;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 12px;
    height: 100%;
}

.anncountement-card {
    width: 450px;
}

.card-text-container {
    font-size: 20px;
}

.annoucement-images-container {
    /* display: flex;
    justify-content: center; */
    margin-top: 12px;
}

.announcement-card {
    width: 550px;
}

.announcement-text {
    text-align: center;
    font-size: 24px;
}

.announcement-container {
    text-align: left;
}

.annoucment-hymn-link {
    display: flex;
    justify-content: center;
}

.kickstarter-icon-container {
    margin-top: 12px;
}

.steam-icon-container {
    margin-top: 12px;
}

.itch-icon-container {
    margin-top: 12px;
}