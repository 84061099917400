.nav-container {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 80px;
    background-color: #000;
    opacity: .95;
    padding-left: 90px;
    z-index: 10000;
}

.nav-link-container {
    margin-left: 16px;

}

.nav-link {
    color: #FFF;
    font-size: 24px;
    margin-left: 32px; 
    text-decoration: none;
}

.nav-link:first-of-type {
    margin-left: 0px;
}

.nav-link:visited {
    color: #FFF;
  }

.nav-link.active {
    color:#FFF;
    border-bottom: 4px solid rgba(109, 28, 149, .9);
}

.nav-item {
    font-size: 24px;
    margin-left: 10px;
}

.full-logo-container {
    margin-top: -9px;
}