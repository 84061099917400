.footer-container {
    margin-bottom: 12px;
}

.footer-logo-container {
    margin-bottom: 12px;
}

.footer-link-container {
    display: flex;
    justify-content: center;
}

.footer-link {
    margin-left: 32px;
}

.footer-link:first-of-type {
    margin-left: 0px;
}

.footer-link {
    color: #FFF;
    font-size: 16px;
    margin-left: 32px; 
    text-decoration: none;
}

.footer-link:first-of-type {
    margin-left: 0px;
}

.footer-link:visited {
    color: #FFF;
  }

.footer-link.active {
    color:#FFF;
    border-bottom: 4px solid rgba(109, 28, 149, .9);
}