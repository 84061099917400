
.main-content-container {
    display: flex;
    justify-content: center;
    background-image: url(./../../images/background.png);
    background-repeat: no-repeat;
    margin-bottom: 12px;
    min-height: 1080px;
}

.content-container {
    margin-bottom: 12px;
    padding-top: 110px;
    width: 80%;
}
