.games-header {
    width: 100px;
}

.games-container {
    display: flex;
    width: 500px;
}

.game-details-container {
    text-align: left;
}

.game-details-title {
    font-size: 24px;
}

.game-details-image {
    margin-top: 12px;
}

.game-details-description {
    margin-top: 12px;
    line-height: 1.2;
}

.game-details-steam {
    margin-top: 12px;
}

.game-details-itch {
    margin-top: 12px;
}

.italics {
    font-style:italic;
}
