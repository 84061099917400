.contact-card {
    width: 725px;
}

.contact-container {
    display: flex;
    text-align: left;
    width: 725px;
}

.contact-header {
    width: 114px;
}

.contact-email-header {
    font-size: 24px;
}

.discord-container {
    margin-left: 62px;
}

.contact-social-container {
    display: flex;
    margin-top: 20px;
}

.contact-email-container {
    line-height: 1.2;
}

.contact-social-item {
    margin-left: 12px;
}

.contact-social-item:first-of-type {
    margin-left: 0px;
}